<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  page: {
    title: "แก้ไขข้อมูลโอนรถข้ามสาขา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขข้อมูลโอนรถข้ามสาขา",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "โอนรถข้ามสาขา",
          active: false,
          href: "/vehicle-transfer",
        },
        {
          text: "แก้ไขข้อมูลโอนรถข้ามสาขา",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      submitted: false,
      searchform: "",
      selectedPO: [],
      interiorColor: "",
      exteriorColor: "",
      optioninteriorColor: [],
      optionExteriorColor: [],

      userBranchId: "",
      rcvdDate: "",
      dueDate: "",
      credit: "",
      taxInvoiceDate: "",
      taxInvoiceNumber: "",
      shippingDate: "",
      shippingNumber: "",
      stock: "",
      supId: "",
      transferCost: "",

      branchSelect: "",
      branchStock: "",

      vin: "",
      chassisNumber: "",
      engineNumber: "",

      poItem: {
        prodId: "",
        nameTh: "",
        prodCode: "",
        pricePerOne: "",
        amount: 0,
        poTotalPrice: "",
        poDcPer: 0,
        poDcPrice: "",
        poNetPrice: "",
        prodNameTh: "",
        vehicleId: "",
        cost: "",
      },
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],

      fieldsPartPO: [
        {
          key: "poDate",
          label: "วันที่(ใบสังซื้อ)",
          sortable: true,
        },
        {
          key: "poCode",
          label: "รหัสใบสั่งซื้่อ",
          sortable: true,
        },
        {
          key: "prodCode",
          sortable: true,
          label: "รหัสสินค้า",
        },
        {
          key: "prodNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        // {
        //   key: "modelCode",
        //   sortable: true,
        //   label: "รหัสรุ่น",
        // },
        {
          key: "modelNameTh",
          sortable: true,
          label: "ชื่อรุ่น",
        },
        {
          key: "exteriorColorNameTh",
          sortable: true,
          label: "สีภายนอก",
        },
        {
          key: "interiorColorNameTh",
          sortable: true,
          label: "สีภายใน",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "amount",
          label: "จำนวน",
          sortable: true,
        },
        {
          key: "accruedAmount",
          label: "คงค้าง",
          sortable: true,
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      filterOnPO: [],
      rowDataPO: [],
      perPagePO: 10,
      currentPagePO: 1,
      filterPO: null,
      pageOptionsPO: [5, 10, 25, 50, 100],
      total_pagesPO: "",
      pagePO: "",
      totalPO: "",
      costPrice: "",

      poDate: "",
      status: "",

      poVatPer: "",
      poTotalPrice: "",
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: "",
      poVatPrice: "",
      poGrandTotalPrice: "",

      productAmount: "",
      productPricePerOne: "",
      productTotalPrice: "",
      productDcPer: "",
      productDcPrice: "",
      productNetPrice: "",
      productVatPer: "",
      productVatPrice: "",
      productGrandTotalPrice: "",

      rowsStock: [],
      optionsPO: [],
      poCode: "",

      nameState: null,

      toBranchId: "",
      transferCode: "",
      transferDate: "",
      note: "",
      cost: "",

      transferdId: "",

      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.transferId) {
      this.getDataDetail();
      this.getDetailProd();
      this.calculatePo();
    }
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-transfer/show", {
          params: {
            transferId: atob(this.$route.params.transferId),
          },
        })
        .then((response) => {
          this.userBranchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.toBranchId = {
            branchId: response.data.data.toBranchId,
            nameTh: response.data.data.toBranchName,
          };

          this.transferCode = response.data.data.transferCode;
          this.transferDate = response.data.data.transferDate;
          this.note = response.data.data.note;
          this.status = response.data.data.status;
          this.cost = response.data.data.cost;
          //   this.rowsPO = response.data.data;
          //   this.rowsPO.to = response.data.to;
          //   this.rowsPO.from = response.data.from;
          //   this.rowsPO.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDetailProd: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-transfer/product", {
          params: {
            transferId: atob(this.$route.params.transferId),
          },
        })
        .then((response) => {
          this.rowsDetail = response.data.data;
          this.calculatePo();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getBranchProduct: function(searchform) {
      // console.log(this.searchform);
      useNetw
        .get("api/vehicle-transfer/branch-vehicle-stock", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.userBranchId != null
                ? this.userBranchId.branchId
                : undefined,
            productNameTh: searchform
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.rowsProduct = response.data.data;
          // this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    addDetailTransfer() {
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-transfer/product/store", {
          transferId: atob(this.$route.params.transferId),
          vehicleId: this.poItem.vehicleId,
          toStockId: this.branchStock.stockId,
          transferCost: this.transferCost.replace(/,/g, ""),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataDetail();
          this.getDetailProd();
          //   this.calculateProduct();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateTransfer() {
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-transfer/update", {
          transferId: atob(this.$route.params.transferId),
          toBranchId:
            this.toBranchId != null ? this.toBranchId.branchId : undefined,
          transferDate: this.transferDate,
          note: this.note,
          cost: this.cost,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    approveReceived() {
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/approve", {
          rcvdId: atob(this.$route.params.rcvdId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitTransfer() {
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-transfer/submit", {
          transferId: atob(this.$route.params.transferId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          this.$router.push({ name: "vehicle-transfer" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alert(poItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteTransfer(poItem, index);
          }
        });
    },
    updateDetailTransfer() {
      //   this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-transfer/product/update", {
          transferdId: this.transferdId,
          vehicleId: this.poItem.vehicleId,
          toStockId:
            this.branchStock != null ? this.branchStock.stockId : undefined,
          transferCost: this.transferCost.replace(/,/g, ""),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataDetail();
          this.getDetailProd();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deleteTransfer: function(poItem) {
      // this.rowsDetail.splice(index, 1);
      this.calculatePo(this.rowsDetail);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/vehicle-transfer/product/delete", {
          data: {
            transferdId: poItem.transferdId,
            transferCost: this.poGrandTotalPrice - poItem.cost,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          // this.getPoShow(this.poId);
          // this.rowPoParts.push(this.rowPoArr)
          this.getDataDetail();
          this.getDetailProd();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันรับรถเข้าคลังหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.approveReceived();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    customLabel({ prodCode, productNameTh, vin }) {
      return `[${prodCode}] - [VIN: ${vin}] - ${productNameTh != null ? productNameTh : ""}`;
    },
    showModal(option) {
      this.selectedPO = option;
      this.poItem.prodId = option != null ? option.prodId : undefined;
      this.poItem.prodCode = option != null ? option.prodCode : undefined;
      this.poItem.pricePerOne = parseFloat(option != null ? option.price : 0);
      this.poItem.cost = parseFloat(option != null ? option.cost : 0);
      this.poItem.vehicleId =
        option != null ? option.vehicleId : undefined;
      this.transferdId =
        option != null ? option.transferdId : undefined;
      if (!this.transferdId) {
        this.transferCost = this.numberFormats(
          parseFloat(option != null ? option.cost : 0) +
            this.poGrandTotalPrice
        );
      } else {
        this.transferCost = this.numberFormats(this.poGrandTotalPrice);
      }
      // this.poItem.totalPrice = option.price;
      // this.poItem.netPrice = option.price;
      this.poItem.nameTh = option != null ? option.productNameTh : undefined;
      // this.poItem.prodNameTh = option.item.prodNameTh;
      // this.poItem.prodCode = option.partId;
      // console.log(option,'option')
      this.$refs.modalAmout.show(option);
    },
    showModalPO(option) {
      this.selectedPO = option.item;
      this.poItem.prodId = option.item.prodId;
      this.poItem.prodCode = option.item.prodCode;
      this.poItem.pricePerOne = parseFloat(option.item.pricePerOne);
      // this.poItem.totalPrice = option.price;
      // this.poItem.netPrice = option.price;
      this.poItem.nameTh = option.item.prodNameTh;
      // this.poItem.prodNameTh = option.item.prodNameTh;
      // this.poItem.prodCode = option.partId;
      this.$refs.modalAmout.show(option.item);
    },
    calculatePo() {
      // console.log(poItem,'poItem')
      let poTotalPrice,
        poDcPrice,
        poNetPrice,
        poVatPrice,
        poGrandTotalPrice,
        poTotalCostPrice;

      poTotalPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.price);

        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.poTotalPrice = poTotalPrice;
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice;
      }

      // poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      // if (!isNaN(poNetPrice)) {
      //   this.poNetPrice = poNetPrice;
      // }

      poTotalCostPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var costPrice = parseFloat(item.cost);

        if (!isNaN(costPrice)) {
          return sum + costPrice;
        }
      }, 0);
      this.costPrice = poTotalCostPrice;

      poNetPrice = parseFloat(this.costPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice;
      }

      poVatPrice = 0; // (this.poNetPrice / 100) * 7;
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice;
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice;
      }
    },
    // calculateProduct(item) {
    //   item.totalPrice = 1 * parseFloat(item.price);
    //   item.dcPrice =
    //     (parseFloat(item.totalPrice) / 100) * parseFloat(item.dcPer);
    //   item.netPrice = parseFloat(item.totalPrice) - parseFloat(item.dcPrice);
    //   this.prodId = item.prodId;
    //   this.productPricePerOne = parseFloat(this.poItem.pricePerOne);
    //   this.poItem.poTotalPrice = 1 * parseFloat(this.poItem.pricePerOne);
    //   this.poItem.poDcPrice =
    //     parseFloat(this.poItem.poTotalPrice / 100) *
    //     parseFloat(this.poItem.poDcPer);
    //   this.poItem.poNetPrice =
    //     parseFloat(this.poItem.poTotalPrice) -
    //     parseFloat(this.poItem.poDcPrice);
    //   this.poItem.vatPrice =
    //     (parseFloat(this.poItem.poNetPrice) / 100) * parseFloat(this.poVatPer);
    //   this.poItem.poGrandTotalPrice =
    //     parseFloat(this.poItem.poNetPrice) + parseFloat(this.poItem.vatPrice);
    //   this.calculatePo();
    // },
    getDataStock: function() {
      this.branchStock = null;
      this.loading = true;
      useNetw
        .get("api/master/stock-vehicle", {
          params: {
            branchId: this.branchSelect.branchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div
              class="col-12 badge"
              style="background-color: #26B99A"
              v-if="this.status === 'APV'"
            >
              <h4 class="mt-2 text-white">
                โอนรถข้ามสาขาแล้ว
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #50a5f1"
              v-if="this.status === 'SUBMIT'"
            >
              <h4 class="mt-2 text-white">
                รอยืนยันการโอนรถข้ามสาขา
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #e74646"
              v-if="this.status === 'CAN'"
            >
              <h4 class="mt-2 text-white">
                ยกเลิกแล้ว
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            จากสาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              disabled
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>ไปยังสาขา:
                            <multiselect
                              v-model="toBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              :loading="loading"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            รหัสโอนรถ:
                            <b-form-input
                              v-model="transferCode"
                              type="search"
                              disabled
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>วันที่โอน:
                            <date-picker
                              v-model="transferDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>ราคา:
                            <b-form-input
                              v-model="cost"
                              type="search"
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                          <div class="mb-3 position-relative">
                            หมายเหตุ:
                            <b-form-textarea
                              v-model="note"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </div>

                        <!-- <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              v-if="this.$route.params.rcvdId"
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              @click="updateReceived"
                            >
                              บันทึกการแก้ไขข้อมูล
                            </b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการสินค้า</p>
                </div>
                <div class="col-6">
                  <!-- <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.status === 'APV'"
                  >
                    สั่งซื้อรถแล้ว
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.status === 'DFT'"
                  >
                    ร่าง
                  </p>
                  <p
                    class="badge bg-danger font-size-14 float-end"
                    v-if="this.status === 'CAN'"
                  >
                    ยกเลิกแล้ว
                  </p> -->
                  <!-- <b-button class="float-end btn btn-success">
                    สั่งซื้อรถเพิ่ม
                  </b-button> -->
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสสินค้า</th>
                      <th scope="col">ชื่อสินค้า</th>
                      <th scope="col">เลขตัวถัง</th>
                      <th scope="col">เลขเครื่องยนต์</th>
                      <th scope="col">เลขตัวถัง</th>
                      <th scope="col">สีภายนอก</th>
                      <th scope="col">สีภายใน</th>
                      <!-- <th scope="col">รหัสรุ่นรถ</th> -->
                      <!-- <th scope="col">ชื่อรุ่นรถ</th> -->
                      <th scope="col">จากคลัง</th>
                      <th scope="col">ไปยังคลัง</th>
                      <!-- <th scope="col">ราคา</th> -->
                      <!-- <th scope="col">จำนวน</th> -->
                      <th scope="col">ราคาต้นทุน</th>
                      <!-- <th scope="col">ส่วนลด</th> -->
                      <!-- <th scope="col">ส่วนลดยอด</th> -->
                      <!-- <th scope="col">หลังลด</th> -->
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.rowsDetail.length > 0">
                    <tr v-for="(poItem, index) in rowsDetail" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ poItem.prodCode }}</td>
                      <td>{{ poItem.productNameTh }}</td>
                      <td>{{ poItem.vin }}</td>
                      <td>
                        {{
                          poItem.engineNumber != null
                            ? poItem.engineNumber
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          poItem.chassisNumber != null
                            ? poItem.chassisNumber
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          poItem.exteriorColorNameTh != null
                            ? poItem.exteriorColorNameTh
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          poItem.interiorColorNameTh != null
                            ? poItem.interiorColorNameTh
                            : "-"
                        }}
                      </td>
                      <!-- <td>{{ poItem.modelCode }}</td> -->
                      <!-- <td>{{ poItem.modelNameTh }}</td> -->
                      <td>{{ poItem.fromStockName }}</td>
                      <td>{{ poItem.toStockName }}</td>
                      <!-- <td class="">
                        <input
                          v-model="poItem.price"
                          class="form-control text-end number"
                          disabled
                          type="number"
                        />
                      </td> -->

                      <!-- <td class="">
                        <input
                          :disabled="status == 'APV'"
                          v-model="poItem.amount"
                          type="number"
                          class="form-control text-end Dc"
                          @keyup.enter="updateDetailVehiclePO(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td> -->
                      <td class="text-end">
                        <input
                          disabled
                          style="width: 130px"
                          v-model="poItem.cost"
                          class="form-control text-end number"
                        />
                      </td>
                      <!-- <td class="">
                        <input
                          :disabled="status == 'APV'"
                          v-model="poItem.dcPer"
                          class="form-control text-end Dc"
                          type="number"
                          @keyup.enter="updateDetailReceived(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="text-end" @change="calculateProduct(poItem)">
                        <input
                          disabled
                          v-model="poItem.dcPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          v-model="poItem.netPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td> -->
                      <td v-if="status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-primary"
                              v-b-tooltip.hover
                              title="Edit"
                              @click="showModal(poItem)"
                            >
                              <i class="uil uil-pen font-size-18"></i>
                            </a>
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(poItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-title-desc">ค้นหารายการสินค้า</p>

              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="row">
                      <span class="text-center font-size-16">ไม่พบรายการ</span>
                    </div>
                    <hr /> -->
                    <div class="row">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label for="partsCode"
                              ><code>*</code> ค้นหารหัสรถ,ชื่อรถ</label
                            >
                            <multiselect
                              id="partsCode"
                              v-model="searchform"
                              :options="rowsProduct"
                              label="prodCode"
                              :custom-label="customLabel"
                              :show-labels="false"
                              track-by="vin"
                              @input="showModal(searchform)"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getBranchProduct($event)"
                              :disabled="status == 'APV' || status == 'SUBMIT'"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <!-- <div class="col-3">
                          <label for="parts">&nbsp;</label><br />
                          <button
                            :disabled="status == 'APV'"
                            class="btn btn-primary"
                            @click="$refs['modalPO'].show()"
                          >
                            <i class="uil uil-search"> </i> เลือกจากใบสั่งซื้อ
                          </button>
                        </div> -->

                        <div></div>
                      </div>
                    </div>
                    <hr />
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดการโอนรถข้ามสาขา</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">
                          รายละเอียดการโอนรถข้ามสาขา
                        </div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่ารถ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              ยอดรวมต้นทุน : <br />
                              <!-- จำนวนเงิน : <br /> -->
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.poTotalPrice) }} <br />

                            {{ this.poDcPer }}<br />
                            {{ this.numberFormats(this.poDcPrice) }} <br />
                            {{ this.numberFormats(this.costPrice) }} <br />
                            <!-- {{ this.numberFormats(this.poNetPrice) }} <br /> -->
                            {{ this.numberFormats(this.poVatPer) }} <br />
                            {{ this.numberFormats(this.poVatPrice) }}
                          </strong>
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="col-md-5 col-sm-5 bg-dark text-white m-0 row align-items-center"
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(
                                      this.poGrandTotalPrice
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br /><br />
                      <div class="row mb-4 mt-4">
                        <div class="col text-end ms-1">
                          <b-button
                            v-if="this.$route.params.transferId"
                            :disabled="status == 'APV' || status == 'SUBMIT'"
                            class="btn m-2"
                            variant="primary"
                            type="submit"
                            @click="updateTransfer"
                          >
                            บันทึกการแก้ไขข้อมูล
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row mb-0 mt-2">
                      <div class="col text-end ms-1">
                        <button
                          :disabled="status == 'SUBMIT' || status == 'APV'"
                          class="btn btn-danger ms-1"
                          @click="submitTransfer()"
                        >
                          ยืนยันการโอนรถข้ามสาขา
                        </button>
                        <p>
                          <!-- <code v-if="this.part.status == 'APV'"
                              >***
                              ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว</code
                            > -->
                          <code
                            >*** ถ้ายืนยันการโอนรถข้ามสาขาแล้ว
                            จะไม่สามารถแก้ไขข้อมูลได้</code
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <b-modal
        ref="modalAmout"
        title="รายการสินค้า"
        hide-footer
        size="sm"
        centered
        :state="nameState"
      >
        <div class="row">
          <!-- <label for="amount"><code>*</code> จำนวน</label> -->
          <span class="badge bg-success mb-3">
            <h5 class="mt-2 text-white">({{ this.poItem.nameTh }})</h5>
            <h6 class="mt-2 text-white">{{ this.poItem.prodCode }}</h6>
            <h6 class="mt-2 text-white">ราคา {{ this.poItem.pricePerOne }}</h6>
            <h6 class="mt-2 text-white">ราคาต้นทุน {{ this.poItem.cost }}</h6>
          </span>
          <div class="col-12">
            <label> สาขา </label>
            <multiselect
              v-model="branchSelect"
              label="nameTh"
              type="search"
              :options="optionBranch"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
              @input="getDataStock"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
          </div>
          <div class="col-12">
            <label> <code> * </code> คลัง </label>
            <multiselect
              v-model="branchStock"
              label="nameTh"
              type="search"
              :options="rowsStock"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
          </div>
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> <code> * </code> ยอดรวมต้นทุน</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="poDcPer"
              v-model="transferCost"
              type="text"
              class="form-control"
              disabled
              value="Otto"
              :class="{
                'is-invalid': submitted && $v.transferCost.$error,
              }"
            />
            <div
              v-if="submitted && $v.transferCost.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.transferCost.required">{{ message }}</span>
            </div>
          </div>
          <!-- <div class="col-6 mt-2 mb-2">
            <label> <code> * </code> จำนวน</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="amount"
              v-model="poItem.amount"
              type="number"
              class="form-control"
              value="Otto"
              @input="calculateProduct(poItem)"
              @change="calculateProduct(poItem)"
              :class="{
                'is-invalid': submitted && $v.poItem.amount.$error,
              }"
            />
            <div
              v-if="submitted && $v.poItem.amount.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.poItem.amount.required">{{ message }}</span>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button
                v-if="!transferdId"
                @click="addDetailTransfer(poItem)"
                class="btn btn-primary"
              >
                บันทึก
              </button>
              <button
                v-if="this.transferdId"
                @click="updateDetailTransfer(poItem)"
                class="btn btn-primary"
              >
                บันทึกการแก้ไขข้อมูล
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- <b-modal
        title="เลือกใบสั่งซื้ออะไหล่"
        hide-footer
        size="xl"
        centered
        ref="modalPO"
      >
        <div class="row">
          <div class="col-md-10">
            <strong><code>* </code>รหัสใบสั้งซื้ออะไหล่</strong>
            <div class="row">
              <div class="col-md-5">
                <input
                  :disabled="status == 'APV'"
                  type="text"
                  v-model="poCode"
                  class="form-control"
                />
              </div>
              <div class="col-md-auto">
                <button
                  class="btn btn-primary"
                  @click="getPoData"
                  :disabled="status == 'APV'"
                >
                  ค้นหาด้วยใบ PO
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <div class="mb-6 position-relative">
              <div class="table-responsive mb-0">
                <b-table
                  :items="optionsPO"
                  :fields="fieldsPartPO"
                  responsive="sm"
                  :per-page="perPagePO"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filterPO"
                  :filter-included-fields="filterOnPO"
                  @filtered="onFilteredPO"
                  ref="selectableTablePO"
                  selectable
                  select-mode="single"
                  @row-selected="onRowSelectedPO"
                >
                  <template v-slot:cell(action)="optionsPO">
                    <a
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="showModalPO(optionsPO)"
                    >
                      <i class="uil uil-plus-square font-size-18"></i>
                      เพิ่มเข้าคลัง
                    </a>
                  </template>

                  </b-table
                ><br />

                <div class="row">

                  <div class="col">
                    <div
                      class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                          v-model="currentPagePO"
                          :total-rows="totalPO"
                          :per-page="perPagePO"
                          @change="handleChangePagePO"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal> -->
    </div>
  </Layout>
</template>
